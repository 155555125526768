<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-card>
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="customs_type"
                    :label="$t('commodity.customs-type')"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="accreditation_number"
                    :label="$t('commodity.name')"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="china_apply_custom_number"
                    :label="$t('commodity.number')"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="supplier"
                    :label="$t('supplier.supplier')"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="commodity_amount"
                    :label="$t('container.commodity_per_box')"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="box_amount"
                    :label="$t('container.box_amount')"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="total_amount"
                    :label="$t('container.total_amount')"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="customer"
                    :label="$t('customer.customer')"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-data-table
              v-model="selected"
              :items="items"
              :headers="headers"
              show-select
              single-select
              fixed-header
              :height="450"
            ></v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions >
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="selected.length!==1"
          @click="save"
        >{{ $t("container.manual-compare") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseDialog from "./BaseDialog";
export default {
  props: {
    items: [],
    target: {}
  },
  data: (vm) => ({
    topic: vm.$i18n.t("container.manual-compare"),
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("container.index"),
        value: "index",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.customs-type"),
        value: "customs_type_str",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.name"),
        value: "accreditation_number",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.number"),
        value: "china_apply_custom_number",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.specification"),
        value: "specification"
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.total_amount"),
        value: "amount",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container-box.apply-custom-price"),
        value: "unit_price",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container-box.total-apply-custom-price"),
        value: "total_price",
      },
    ],
    selected: [],
  }),
  computed: {
    customs_type(){
      return (this.target&&this.target.customs_type)?this.target.customs_type.name_tc:''
    },
    accreditation_number(){
      return (this.target?this.target.accreditation_number:'')
    },
    china_apply_custom_number(){
      return (this.target?this.target.china_apply_custom_number:'')
    },
    commodity_amount(){
      return (this.target?this.target.commodity_amount:'')
    },
    box_amount(){
      return (this.target?this.target.container_box.box_amount:'')
    },
    total_amount(){
      return (this.target?this.target.total_amount:'')
    },
    supplier(){
      return (this.target?this.target.container_box.supplier.name_tc:'')
    },
    customer(){
      return (this.target?this.target.container_box.customer.name_tc:'')
    },
  },
  mixins: [BaseDialog],
  methods: {
    save(){
      if (this.selected.length==1) {
        this.$emit("onSave", this.target, this.selected[0]);
        this.selected = []
      }
      
    },
  },
}
</script>