<template>
    <v-container fluid>
      <CommonAlertDialog
        :show="showAlert"
        :item="alertItem"
        v-on:onCloseAlert="onCloseAlert"
      >
      </CommonAlertDialog>
      <CustomDeliverFeeAfterDialog
        :show="showDialog"
        :items="declaration_items"
        :target="compare_target"
        v-on:onSave="onManualCompare"
        v-on:onClose="onCloseEvent"
      ></CustomDeliverFeeAfterDialog>
      <v-row>
        <v-col>
          <v-btn
            icon
            class="hidden-xs-only"
            @click="$router.push({
                name: 'CustomDeliverFeeAfterList',
                params: {}
              })"
          >
            <v-icon>mdi-keyboard-backspace</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <base-material-card
        icon="mdi-clipboard-text"
        :title="$t('container.error-index')"
        class="px-5 py-3"
      >
        <v-data-table
          :headers="getHeaders(1)"
          :items="uncompare_items"
          :footer-props="footerProps"
        >
          <template v-slot:top="{pagination, options, updateOptions}">
            <v-data-footer 
              :pagination="pagination" 
              :options="options"
              @update:options="updateOptions"
              :show-first-last-page="true"
              :show-current-page="true"
              first-icon="mdi-chevron-double-left"
              last-icon="mdi-chevron-double-right"
              prev-icon="mdi-chevron-left"
              next-icon="mdi-chevron-right"
              :items-per-page-options="[20, 50, 100, 200]"
            />
          </template>
          <template v-slot:item.total_amount="{ item }">
            {{ get_total_amount(item) }}
          </template>
          <template v-slot:item.total_apply_custom_price="{ item }">
            {{ get_total_apply_custom_price(item) }}
          </template>
          <template v-slot:item.customs_type="{ item }">
            {{ get_customs_type_name(item) }}
          </template>
          <template v-slot:item.supplier="{ item }">
            {{ get_supplier_name(item) }}
          </template>
          <template v-slot:item.customer="{ item }">
            {{ get_customer_name(item) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  large
                  class="mr-2"
                  v-on="on"
                  @click="manualCompare(item)"
                >mdi-link</v-icon>
              </template>
              <span>{{ $t("container.manual-compare") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  large
                  class="mr-2"
                  v-on="on"
                  @click="forceCompare(item)"
                >mdi-checkbox-marked-circle-outline</v-icon>
              </template>
              <span>{{ $t("container.force-compare") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </base-material-card>
      <base-material-card
        icon="mdi-clipboard-text"
        :title="$t('container.custom-deliver-fee') + '--' + container_name"
        class="px-5 py-3"
      >
        <v-data-table
          :headers="getHeaders(2)"
          :items="items"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="loading"
          :footer-props="footerProps"
          multi-sort
          fixed-header
          :height="windowHeight*0.8"
          show-expand
          :expanded.sync="expanded"
          :single-expand="true"
        >
          <template v-slot:top="{pagination, options, updateOptions}">
            <v-data-footer 
              :pagination="pagination" 
              :options="options"
              @update:options="updateOptions"
              :show-first-last-page="true"
              :show-current-page="true"
              first-icon="mdi-chevron-double-left"
              last-icon="mdi-chevron-double-right"
              prev-icon="mdi-chevron-left"
              next-icon="mdi-chevron-right"
              :items-per-page-options="[20, 50, 100, 200]"
            />
          </template>
          <template v-slot:item.total_amount="{ item }">
            {{ get_declaration_total_amount(item) }}
          </template>
          <template v-slot:item.custom_deliver_fee="{ item }">
            {{ item.custom_deliver_fee }}
          </template>
          <template v-slot:item.total_apply_custom_price="{ item }">
            {{ get_declaration_total_apply_custom_price(item) }}
          </template>
          <template v-slot:item.customs_type="{ item }">
            {{ get_customs_type_name(item) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  large
                  class="mr-2"
                  v-on="on"
                  @click="cancelManualCompare(item)"
                  v-if="item.declaration_item"
                >mdi-link-off</v-icon>
              </template>
              <span>{{ $t("container.cancel-manual-compare") }}</span>
            </v-tooltip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <template v-if="item.declaration_item">
              <td>
                <v-text-field
                  v-model="item.declaration_item.index"
                  :label="$t('container.index')"
                  readonly
                  px-1
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="item.declaration_item.customs_type_str"
                  :label="$t('commodity.customs-type')"
                  readonly
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="item.declaration_item.accreditation_number"
                  :label="$t('commodity.name')"
                  readonly
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="item.declaration_item.china_apply_custom_number"
                  :label="$t('commodity.number')"
                  readonly
                ></v-text-field>
              </td>
              <td></td>
              <td></td>
              <td>
                <v-text-field
                  v-model="item.declaration_item.amount"
                  :label="$t('container.total_amount')"
                  readonly
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="item.declaration_item.unit_price"
                  :label="$t('container-box.apply-custom-price')"
                  readonly
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="item.declaration_item.total_price"
                  :label="$t('container-box.total-apply-custom-price')"
                  readonly
                ></v-text-field>
              </td>
            </template>
            <template v-else>
              <td></td>
              <td :colspan="headers.length-1">-</td>
            </template>
          </template>
        </v-data-table>
      </base-material-card>
    </v-container>
  </template>
    <script>
  import BaseTable from "./BaseTable.vue";
  import CommonAlertDialog from "../dialogs/CommonAlertDialog";
  import CustomDeliverFeeAfterDialog from "../dialogs/CustomDeliverFeeAfterDialog"
  import { action_items } from "@/definition.js";
  export default {
    data: (vm) => ({
      url: "/container/container_box_relation/",
      headersMap: {
        id: {
          sortable: true,
          text: vm.$i18n.t("ID"),
          value: "id",
        },
        accreditation_number:{
          sortable: true,
          text: vm.$i18n.t("commodity.name"),
          value: "accreditation_number",
        },
        china_apply_custom_number:{
          sortable: true,
          text: vm.$i18n.t("commodity.number"),
          value: "china_apply_custom_number",
        },
        commodity_amount:{
          sortable: true,
          text: vm.$i18n.t("container.commodity_per_box"),
          value: "commodity_amount",
        },
        box_amount: {
          sortable: true,
          text: vm.$i18n.t("container.box_amount"),
          value: "container_box.box_amount",
        },
        total_amount: {
          sortable: true,
          text: vm.$i18n.t("container.total_amount"),
          value: "total_amount",
        },
        apply_custom_price: {
          sortable: true,
          text: vm.$i18n.t("commodity.unit-price"),
          value: "apply_custom_price",
        },
        total_apply_custom_price: {
          sortable: true,
          text: vm.$i18n.t("container-box.apply-custom-price"),
          value: "total_apply_custom_price",
        },
        customs_type: {
          sortable: true,
          text: vm.$i18n.t("commodity.customs-type"),
          value: "customs_type",
        },
        supplier: {
          sortable: true,
          text: vm.$i18n.t("supplier.supplier"),
          value: "supplier",
        },
        customer: {
          sortable: true,
          text: vm.$i18n.t("customer.customer"),
          value: "customer",
        },
        expand:{
          text: '', 
          value: 'data-table-expand' 
        },
        actions: {
          sortable: false,
          text: vm.$i18n.t("actions"),
          value: "actions",
        },
      },
      uncompareHeaders: ['id','supplier','customer','customs_type','accreditation_number','china_apply_custom_number','commodity_amount','box_amount','total_amount','apply_custom_price','total_apply_custom_price','actions'],
      comparedHeaders: ['id','customs_type','accreditation_number','china_apply_custom_number','commodity_amount','box_amount','total_amount','apply_custom_price','total_apply_custom_price','expand','actions'],
      action_items: action_items,
      container: null,
      container_name: null,
      mode: 1,
      uncompare_items: [],
      getErrorParams: {},
      declaration_items: [],
      compare_target: null,
      expanded: [],
    }),
    mixins: [BaseTable],
    components: {
      CommonAlertDialog,
      CustomDeliverFeeAfterDialog,
    },
    methods: {
      getHeaders (type) {
        var headers = []
        var headerKeys = this.uncompareHeaders
        if (type===2) {
          headerKeys = this.comparedHeaders
        }
        headerKeys.forEach(key => {
          headers.push(this.headersMap[key])
        })
        return headers
      },
      get_total_amount(item) {
        return item.commodity_amount * item.container_box.box_amount;
      },
      get_declaration_total_amount(item) {
        return item.declaration_item ? item.declaration_item.amount : ''
      },
      get_total_apply_custom_price(item) {
        if (item.apply_custom_price_package==true) {
          return item.container_box.box_amount * item.apply_custom_price;
        }
        return item.commodity_amount * item.container_box.box_amount * item.apply_custom_price;
      },
      get_declaration_total_apply_custom_price(item) {
        return item.declaration_item ? item.declaration_item.total_price : ''
      },
      manualCompare(item){
        console.log(item)
        this.compare_target = item
        this.compare_target.total_amount = item.commodity_amount * item.container_box.box_amount
        this.getApi('/container/container/'+item.container_box.container+'/getUncompareDeclarationItem/',this.setDeclarationItem)
      },
      setDeclarationItem(data){
        console.log(data)
        this.declaration_items = data
        this.showDialog = true
      },
      onCloseEvent() {
        this.showDialog = false;
      },
      setResult(data){
        console.log('setResult')
        this.totalItems = data.count;
        this.items = data.results;
      },
      setUncompareResult(data){
        console.log('setUncompareResult')
        // this.totalItems = data.count;
        this.uncompare_items = data.results;
      },
      getUncompareRelations(){
        console.log('getUncompareRelations')
        let params = {
          container_box__container: this.container,
          apply_custom_price_check: false
        };
        this.getApi(this.url, this.setUncompareResult,false,{},params,{itemsPerPage:200});
      },
      onManualCompare(relation,declaration_item){
        this.showDialog = false
        console.log(relation,declaration_item)
        this.postApi({declaration_item_id:declaration_item.id},"/container/container_box_relation/"+relation.id+"/manualCompare/",this.refreshData)
      },
      cancelManualCompare(item){
        if (confirm(this.$i18n.t('cancel-manual-compare-confirm'))) {
          this.postApi({},"/container/container_box_relation/"+item.id+"/cancelManualCompare/",this.refreshData)
        }
      },
      forceCompare(item){
        if (confirm(this.$i18n.t('force-compare-confirm'))) {
          this.postApi({},"/container/container_box_relation/"+item.id+"/forceCompare/",this.refreshData)
        }
      },
      get_customs_type_name(item){
        return item.customs_type?this.getHanText(item.customs_type,"name"):""
      },
      get_supplier_name(item) {
        return this.getHanText(item.container_box.supplier, "name");
      },
      get_customer_name(item) {
        return this.getHanText(item.container_box.customer, "name");
      },
      refreshData(){
        this.getApi()
        this.getUncompareRelations()
      },
    },
    watch: {
      options(val,oldval){
        console.log('options',val,oldval)
      }
    },
    mounted() {
      this.container = this.$route.query.container;
      this.mode = this.$route.query.mode;
      this.getParams = {
        container_box__container: this.container,
        apply_custom_price_check: true
      };
      this.container_name = this.$route.query.name;
  
      this.options = {
        sortBy: ["customs_type__order"],
        sortDesc: [true],
        itemsPerPage: 200,
      };
      this.footerProps.itemsPerPageOptions = [20, 50, 100, 200]
      this.getUncompareRelations()
    },
  };
  </script>
  <style>
  .style-1 {
    /* background-color: rgb(215,215,44) */
  }
  .style-2 {
    /* background-color: rgb(135, 194, 132) */
  }
  </style>
